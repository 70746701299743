import React, { useState } from 'react'
import SideMenu from './layouts/SideMenu'
import TopBar from './layouts/TopBar'
import MobileMenu from './layouts/MobileMenu'
import { DataGrid } from '@mui/x-data-grid';
import GeneralReport from './layouts/GeneralReport';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Modal } from '@mui/material';
import './cssClasses/custom.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getBearerToken, postBearerToken, putBearerToken } from '../../utils/Apis';
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

const A_add_admin = () => {

    const location = useLocation()
    console.log(location)
    React.useEffect(() => {
        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();
        return () => {
            // document.body.removeChild(externalScript);
        };
    }, []);


    const [firstName, setfirstName] = React.useState("")
    const [email, setemail] = useState("")
    const [phone, set_phone] = React.useState("")
    const [from_date, set_from_date] = React.useState("")
    const [to_date, set_to_date] = React.useState("")
    const [slug_name, set_slug_name] = React.useState("")
    const [password, set_password] = React.useState("")
    const [confirm_password, set_confirm_password] = React.useState("")
    const [show_password, set_show_password] = React.useState("")
    const [is_active, setis_active] = useState("")
    const [room, set_room] = React.useState("")
    const [client_id, set_client_id] = React.useState("")
    const [client_secret, set_client_secret] = React.useState("")
    const [s2s_client_id, sets2s_client_id] = useState("")
    const [s2s_client_secret, sets2s_client_secret] = useState("")
    const [oauth_id, set_oauth_id] = React.useState("")
    const [device_id, setdevice_id] = React.useState("")
    const [message, setmessage] = useState("")
    const [messageView, setmessageView] = useState(false)
    const [loader, setloader] = useState(false)
    const [fetchLoader, setfetchLoader] = useState(false)

    const [user_data, setuser_data] = useState(null)

    React.useEffect(() => {
        if (location?.state?.id) {
            fetch_users()
        }
    }, [])

    React.useEffect(() => {
        fetch_rooms()
    }, [])


    const fetch_users = async () => {
        setfetchLoader(true)
        getBearerToken("users?_id=" + location.state.id).then((result) => {
            if (result.error == 0) {
                console.log(result.data)
                setuser_data(result.data)


                setfirstName(result.data.first_name)
                set_room(result.data.room)
                setemail(result.data.email)
                set_phone(result.data.phone)
                set_from_date(moment(result.data.from_date).toDate())
                set_to_date(moment(result.data.to_date).toDate())
                set_slug_name(result.data.slug_name)
                // set_room(result.data?.room?._id)
                set_client_id(result.data.client_id)
                set_client_secret(result.data.client_secret)
                sets2s_client_id(result.data.s2s_client_id)
                sets2s_client_secret(result.data.s2s_client_secret)
                set_oauth_id(result.data.oauth_id)
                setdevice_id(result.data.device_id)
                setis_active(result.data.is_active == true ? "Active" : "Inactive")
                setfetchLoader(false)


            } else {
                setfetchLoader(false)

                // notify(JSON.stringify(result.messages))
            }
        })
    }

    const submit = async () => {
        setloader(true)


        let url = ""

        if (location.pathname == "/create_sub_admin") {
            url = "users"
        }
        if (location.pathname == "/edit_sub_admin") {
            url = "users?_id=" + location.state.id
        }

        let json;

        if (location.pathname == "/create_sub_admin") {
            json = {
                first_name: firstName,
                phone: phone,
                email: email,
                // from_date: from_date,
                // to_date: to_date,
                slug_name: slug_name,
                password: password,
                show_password: password,
                is_active: is_active == "Active" ? true : false,
                client_id: client_id,
                client_secret: client_secret,
                s2s_client_id: s2s_client_id,
                s2s_client_secret: s2s_client_secret,

                oauth_id: oauth_id,
                type: client_id != "" && client_secret != "" && oauth_id != "" ? "SubAdmin" : "User"
            }
            if (room != "") {
                json["room"] = room
            }
        }
        if (location.pathname == "/edit_sub_admin") {
            if (password != "") {
                json = {

                    first_name: firstName,
                    phone: phone,
                    email: email,
                    from_date: from_date,
                    to_date: to_date,
                    password: password,
                    show_password: password,
                    is_active: is_active == "Active" ? true : false,
                    room: room,
                    client_id: client_id,
                    client_secret: client_secret,
                    s2s_client_id: s2s_client_id,
                    s2s_client_secret: s2s_client_secret,
                    oauth_id: oauth_id,
                    device_id : device_id,
                    type: client_id != "" && client_secret != "" && oauth_id != "" ? "SubAdmin" : "User"
                }
            } else {
                json = {

                    first_name: firstName,
                    phone: phone,
                    from_date: from_date,
                    to_date: to_date,
                    email: email,
                    is_active: is_active == "Active" ? true : false,
                    room: room,
                    client_id: client_id,
                    client_secret: client_secret,
                    s2s_client_id: s2s_client_id,
                    s2s_client_secret: s2s_client_secret,
                    oauth_id: oauth_id,
                    device_id : device_id,
                    type: client_id != "" && client_secret != "" && oauth_id != "" ? "SubAdmin" : "User"
                }
            }
        }
        console.log(json)

        if (location.pathname == "/create_sub_admin") {

            if (password == "" || slug_name == "" || phone == "") {
                setmessage("Password , Username , Phone are Compulsary")
                setmessageView(true)
                setloader(false)
            } else {

                if (confirm_password == password) {
                    postBearerToken(url, json).then((result) => {
                        if (result.error == 0) {
                            setfirstName("")
                            set_phone("")
                            set_from_date("")
                            setemail("")
                            set_to_date("")
                            set_slug_name("")
                            set_password("")
                            set_confirm_password("")
                            set_show_password("")
                            set_room("")
                            set_client_id("")
                            set_client_secret("")
                            set_oauth_id("")
                            sets2s_client_id('')
                            sets2s_client_secret("")
                            setmessage("Success")
                            setmessageView(true)
                            setloader(false)
                        } else {
                            setmessage(result.message)
                            setmessageView(true)
                            setloader(false)

                        }

                    })
                } else {
                    setmessage("Password Mismatch")
                    setmessageView(true)
                    setloader(false)
                }
            }
        }
        if (location.pathname == "/edit_sub_admin") {
            if (phone == "") {
                setmessage("Phone are Compulsary")
                setmessageView(true)
                setloader(false)
            } else {

                if (confirm_password == password) {
                    putBearerToken(url, json).then((result) => {
                        if (result.error == 0) {
                            setmessage("Success")
                            setmessageView(true)
                            setloader(false)
                        } else {
                            setmessage(result.message)
                            setmessageView(true)
                            setloader(false)

                        }

                    })
                } else {
                    setmessage("Password Mismatch")
                    setmessageView(true)
                    setloader(false)
                }
            }
        }



    }
    const [rooms, setrooms] = useState([])

    const fetch_rooms = async () => {
        getBearerToken("room").then((result) => {
            if (result.error == 0) {
                console.log(result.data)
                setrooms(result.data)
            } else {
                // notify(JSON.stringify(result.messages))
            }
        })
    }


    return (
        <div className="py-5 md:py-0">
            {/* <!-- BEGIN: Mobile Menu --> */}
            <div className="mobile-menu md:hidden">
                <MobileMenu />

            </div>
            {/* <!-- END: Mobile Menu -->
        <!-- BEGIN: Top Bar --> */}
            <TopBar />
            {/* <!-- END: Top Bar --> */}
            <div className="flex overflow-hidden">
                {/* <!-- BEGIN: Side Menu --> */}
                <SideMenu />
                {/* <!-- END: Side Menu -->
            <!-- BEGIN: Content --> */}
                <div className="content">

                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-12">



                            {fetchLoader == false ?

                                <div class="grid grid-cols-12 gap-6 mt-5">
                                    <div class="intro-y col-span-12 lg:col-span-12">
                                        <div class="intro-y box p-5">
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>User Type</label>
                                                <select className='form-control'
                                                    value={room}
                                                    onChange={(e) => set_room(e.target.value)}
                                                >
                                                    <option value=""> Select Meeting </option>
                                                    {
                                                        rooms && rooms.length > 0
                                                            ?
                                                            rooms.map((item, index) => (
                                                                <option value={item._id}> {item.name} </option>
                                                            ))
                                                            :
                                                            null
                                                    }

                                                </select>
                                            </div>

                                            <div className='mt-2' >
                                                <label style={{ display: "flex", justifyContent: "start" }}>Full Name</label>
                                                <input id="crud-form-1"
                                                    value={firstName}
                                                    onChange={(e) => setfirstName(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2' >
                                                <label style={{ display: "flex", justifyContent: "start" }}>Mobile Number</label>

                                                <input id="crud-form-1"
                                                    value={phone}
                                                    onChange={(e) => set_phone(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            {/* <div className='mt-2' style={{ width: "80%", justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
                                                <div>
                                                    <label style={{ display: "flex", justifyContent: "start" }}>Start Date</label>

                                                    <DatePicker className="form-control w-full" selected={from_date}
                                                        onChange={(date) => set_from_date(date)} />
                                                </div>
                                                <div>
                                                    <label style={{ display: "flex", justifyContent: "start" }}>Expiry Date</label>

                                                    <DatePicker id="crud-form-1" className="form-control w-full" selected={to_date}
                                                        onChange={(date) => set_to_date(date)} />
                                                </div>
                                            </div> */}

                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>User Name</label>
                                                <input id="crud-form-1"
                                                    value={slug_name}
                                                    onChange={(e) => set_slug_name(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Password</label>
                                                <input id="crud-form-1"
                                                    value={password}
                                                    onChange={(e) => set_password(e.target.value)}
                                                    type="password" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Confirm Password</label>
                                                <input id="crud-form-1"
                                                    value={confirm_password}
                                                    onChange={(e) => set_confirm_password(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Email</label>
                                                <input id="crud-form-1"
                                                    value={email}
                                                    onChange={(e) => setemail(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>O Auth Account Id</label>
                                                <input id="crud-form-1"
                                                    value={oauth_id}
                                                    onChange={(e) => set_oauth_id(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Client Id</label>
                                                <input id="crud-form-1"
                                                    value={client_id}
                                                    onChange={(e) => set_client_id(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Client Secret</label>
                                                <input id="crud-form-1"
                                                    value={client_secret}
                                                    onChange={(e) => set_client_secret(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>S2S Client ID</label>
                                                <input id="crud-form-1"
                                                    value={s2s_client_id}
                                                    onChange={(e) => sets2s_client_id(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>S2S Client Secret</label>
                                                <input id="crud-form-1"
                                                    value={s2s_client_secret}
                                                    onChange={(e) => sets2s_client_secret(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Device Id</label>
                                                <input id="crud-form-1"
                                                    value={device_id}
                                                    onChange={(e) => setdevice_id(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>

                                            <div className="mt-4" style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                                                <input type="radio" checked={is_active == "Active" ? true : false} name="is_active" className='mr-2' onClick={() => setis_active("Active")} /> Active
                                                <input type="radio" checked={is_active == "Inactive" ? true : false} name="is_active" className='mr-2 ml-4' onClick={() => setis_active("Inactive")} /> Inactive
                                            </div>
                                            <div class="intro-y col-span-12 lg:col-span-12">

                                                {messageView ?
                                                    <div onClick={() => setmessageView(false)} className='w-80' style={{ background: "lightblue", borderWidth: 1, borderRadius: 3, padding: 10, display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                                                        <label>{message}</label>
                                                        <div onClick={() => setmessageView(false)} style={{ cursor: "pointer" }} className="side-menu__icon"> <i data-lucide="x"></i> </div>
                                                    </div>
                                                    : null}

                                            </div>
                                            {
                                                loader
                                                    ?
                                                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                                                        <i data-loading-icon="circles" class="w-8 h-8"></i>
                                                        <div class="text-center text-xs mt-2">Loading</div>
                                                    </div>
                                                    :
                                                    <div class="text-right mt-5">
                                                        {/* <button type="button" class="btn btn-outline-secondary w-24 mr-1">Cancel</button> */}
                                                        <button onClick={() => submit()} type="button" class="btn btn-primary w-24">Save</button>
                                                    </div>
                                            }


                                        </div>
                                    </div>
                                    {/* <div className="grid grid-cols-12 gap-12">
                                        <div className="col-span-12 2xl:col-span-12">
                                            <div class="grid grid-cols-12 gap-12 mt-5 w-100">
                                                <div class="intro-y col-span-12 lg:col-span-12">

                                                    {messageView ?
                                                        <div className='w-100' style={{ background: "lightblue", borderWidth: 1, borderRadius: 3, padding: 10, display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                                                            <label>{message}</label>
                                                            <div onClick={() => setmessageView(false)} style={{ cursor: "pointer" }} className="side-menu__icon"> <i data-lucide="x"></i> </div>
                                                        </div>
                                                        : null}

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                :
                                null
                            }
                        </div>

                    </div>

                </div>
                {/* <!-- END: Content --> */}
            </div>


        </div>
    )
}

export default A_add_admin