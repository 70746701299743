import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GlobalContext } from "./ContextProvider";
import Login from "../pages/Login";
import OAuth from "../pages/OAuth";
import Sa_add_admin from "../pages/SuperAdmin/Sa_add_admin";
import Sa_admin_list from "../pages/SuperAdmin/Sa_admin_list";
import A_admin_list from "../pages/Admin/A_admin_list";
import A_add_admin from "../pages/Admin/A_add_admin";
import A_add_room from "../pages/Admin/A_add_room";
import A_room_list from "../pages/Admin/A_room_list";
import Protected from "./Protected";
import A_profile from "../pages/Admin/A_profile";
import Audiolist from "../pages/Audiolist";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const MainNavigation = () => {

    const { isGlobalAccess, setGlobalAccess, isGlobalAccessType } = React.useContext(GlobalContext);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="login" element={<Login />} />

                <Route path="audio_user/:_id" element={<Audiolist />} />
                <Route path="audio_room/:_id" element={<Audiolist />} />
                
                <Route path="privacy_policy" element={<PrivacyPolicy />} />

                {/* <Route path="admin-dashboard" element={<A_Dashboard />} /> */}

                {/* <Route path="create_admin" element={<Sa_add_admin />} />
                <Route path="edit_admin" element={<Sa_add_admin />} />
                <Route path="admin_list" element={<Sa_admin_list />} />
                <Route path="/" element={<Sa_admin_list />} /> */}

{/* 
                <Route path="create_sub_admin" element={<A_add_admin />} />
                <Route path="edit_sub_admin" element={<A_add_admin />} />
                <Route path="sub_admin_list" element={<A_admin_list />} />

                <Route path="create_room" element={<A_add_room />} />
                <Route path="edit_room" element={<A_add_room />} />
                <Route path="room_list" element={<A_room_list />} /> */}


                <Route path="/auth" element={<OAuth />} />
                <Route path="/auth/:_id" element={<OAuth />} />

{/* SA */}

                <Route
                    path="/"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "SA"
                                    ?
                                    <Sa_admin_list />
                                    :
                                    isGlobalAccessType == "Admin"
                                    ?
                                    <A_admin_list />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />
                <Route
                    path="/admin_list"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "SA"
                                    ?
                                    <Sa_admin_list />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />
                <Route
                    path="/edit_admin"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "SA"
                                    ?
                                    <Sa_add_admin />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />
                <Route
                    path="/create_admin"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "SA"
                                    ?
                                    <Sa_add_admin />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />

                    {/* Admin */}
                <Route
                    path="/create_sub_admin"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "Admin"
                                    ?
                                    <A_add_admin />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />
                <Route
                    path="/edit_sub_admin"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "Admin"
                                    ?
                                    <A_add_admin />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />
                <Route
                    path="/sub_admin_list"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "Admin"
                                    ?
                                    <A_admin_list />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />
                <Route
                    path="/create_room"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "Admin"
                                    ?
                                    <A_add_room />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />
                <Route
                    path="/edit_room"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "Admin"
                                    ?
                                    <A_add_room />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />
                <Route
                    path="/room_list"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "Admin"
                                    ?
                                    <A_room_list />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <Protected isLoggedIn={isGlobalAccess ? true : false}>
                            {
                                isGlobalAccessType == "Admin"
                                    ?
                                    <A_profile />
                                    :
                                    <Login />
                            }
                        </Protected>
                    }
                />




            </Routes>
        </BrowserRouter>
    );
};

export default MainNavigation;
