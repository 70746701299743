import React, { useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from '@mui/material';
import { GlobalContext } from '../navigation/ContextProvider';
import { getBearerToken, loginApi, projectname } from '../utils/Apis';
import { Constants } from '../utils/Constants';
import 'react-h5-audio-player/lib/styles.css';
import AudioPlayer from 'react-h5-audio-player';

const AudioList = () => {

    const { isGlobalAccess, setGlobalAccess, isGlobalAccessType, setGlobalAccessType } = React.useContext(GlobalContext)
    const navigate = useNavigate()
    const [phone, setphone] = React.useState("")
    const [password, setpassword] = React.useState("")
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupAll, setShowPopupAll] = useState(false);

    const [_id, set_id] = useState("")

    const [error, seterror] = React.useState(false)
    const [message, setmessage] = React.useState("")
    const handleClose = () => {
        seterror(false)
    }

    let pathname = window.location.pathname
    let user_id = pathname.split("/")[2]
    let path = pathname.split("/")[1]

    const [rendered, setrendered] = React.useState(0)
    const [page, setpage] = useState(1)
    const [totalPages, settotalPages] = useState(0)
    const [loader, setloader] = useState(true)

    React.useEffect(() => {
        // Create link element
        const link = document.createElement('link');
        link.href = 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css';
        link.rel = 'stylesheet';
        link.integrity = 'sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH';
        link.crossOrigin = 'anonymous';


        const link2 = document.createElement('link');
        link2.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css';
        link2.rel = 'stylesheet';
        link2.integrity = 'sha512-SnH5WK+bZxgPHs44uWIX+LLJAJ9/2PkPKZ5QiAj6Ta86w+fsb2TkcmfRyVX3pBnMFcV7oQPJkl9QevSCWr3W6A==';
        link2.crossOrigin = 'anonymous';

        // Append link element to head
        document.head.appendChild(link);
        document.head.appendChild(link2);

        // Cleanup: remove link element when component unmounts
        return () => {
            document.head.removeChild(link);
            document.head.removeChild(link2);
        };
    }, []);

    React.useEffect(() => {
        fetch_audios()
    }, [rendered, page])
    const [data, setdata] = React.useState([])
    const fetch_audios = async () => {
        setloader(true)
        if (path == "audio_room") {
            getBearerToken("audios?page=" + page + "&room=" + user_id).then((result) => {
                if (result.error == 0) {
                    // console.log(result.data)
                    setdata(result.data)
                    settotalPages(result.noofpages)
                    setloader(false)
                } else {
                }
            })
        } else if (path == "audio_user") {
            getBearerToken("audios?page=" + page + "&user=" + user_id).then((result) => {
                if (result.error == 0) {
                    // console.log(result.data)
                    setdata(result.data)
                    settotalPages(result.noofpages)
                    setloader(false)
                } else {
                }
            })
        }
    }

    const delete_this_audio = async (_id) => {
        if (path == "audio_room") {
            // if (window.confirm('Are you sure you wish to delete this audio?')) {
            getBearerToken("delete_this_audio?_id=" + _id).then((result) => {
                if (result.error == 0) {
                    setrendered(o => o + 1)
                } else {
                }
            })
            // }
        }
    }


    const delete_all_audio = async () => {
        if (path == "audio_room") {

            // if (window.confirm('Are you sure you wish to delete all audios?')) {
            getBearerToken("delete_all_audio?room=" + user_id).then((result) => {
                if (result.error == 0) {
                    setrendered(o => o + 1)
                } else {
                }
            })
            // }
        }


    }

    const formatTimestamp = (timestamp) => {
        const optionsDate = { day: '2-digit', month: 'long', year: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };
        const date = new Date(timestamp).toLocaleDateString('en-US', optionsDate);
        const time = new Date(timestamp).toLocaleTimeString('en-US', optionsTime);
        return `${date}, ${time}`;
    };


    const handlepopupconfirm = () => {
        if (showPopupAll == true) {
            delete_all_audio()
        } else {
            delete_this_audio(_id)
        }
        set_id("")
        setShowPopupAll(false);
        setShowPopup(false);
    }

    // return (
    //     <div className="container">

    //         {
    //             path == "audio_room"
    //                 ?
    //                 <>
    //                     <button onClick={() => delete_all_audio()} style={{ cursor: "pointer", background: "red", borderColor: "red", color: "white", paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5, marginTop: 10, marginBottom: 10 }}>Delete All</button>
    //                     <hr></hr>
    //                 </>
    //                 :
    //                 null
    //         }

    //         {
    //             data?.map((item, index) => (
    //                 <div className='row'>
    //                     <div className='col-12'>
    //                         <audio controls>
    //                             <source src={"https://ztalk.in:5112/" + item.file} type="audio/mpeg" />
    //                             Your browser does not support the audio element.
    //                         </audio>
    //                         <br></br>
    //                         <label>{item?.user?.slug_name}, {item?.created_at.split("T")[0]}  {item?.created_at.split("T")[1].split(".")[0]}</label>

    //                         {
    //                             path == "audio_room"
    //                                 ?
    //                                 <div>
    //                                 {/* <Link style={{ color: "red", cursor: "pointer"  ,  color: "purple" , marginLeft: 10 , marginRight:10}} to={"https://ztalk.in:5112/"+item.file} target={"_blank"} >Download</Link> */}
    //                                 <label style={{ color: "red", cursor: "pointer" }} onClick={() => delete_this_audio(item._id)} >Delete</label>
    //                                 </div>
    //                                 :
    //                                 null
    //                         }

    //                         <hr></hr>
    //                     </div>
    //                 </div>
    //             ))
    //         }

    //     </div>
    // )


    return (
        <>
            {
                loader ?
                    <div className="loader">
                        <div className="spinner"></div>
                        <span style={{marginLeft:10}}>Please wait! Data loading</span>
                    </div> : null
            }

            {
                path == "audio_room"
                    ?
                    <section style={{ marginTop: '1rem' }}>
                        <div className="container">
                            <div className="d-flex justify-content-center">
                                <button
                                    className="btn  bg-white text-danger px-5 shadow"
                                    onClick={() => {
                                        setShowPopupAll(true)
                                        setShowPopup(true)
                                    }}
                                >
                                    <i className="fas fa-trash text-danger"></i> Delete All
                                </button>
                            </div>
                        </div>
                    </section>
                    :
                    null

            }

            <section style={{ padding: '2rem 0' }}>
                <div className="container">
                    <div className="row">
                        {data?.map((item) => (
                            <div key={item._id} className="col-sm-6 col-md-4 mb-4">
                                <div className="card shadow" style={{ backgroundColor: 'rgb(22, 78, 99)', borderRadius: '0.5rem' }}>
                                    <div className="card-body position-relative">
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <h5 className="card-title text-white" style={{ fontSize: '1rem', fontWeight: 'bold' }}>{item?.user?.slug_name}</h5>
                                            <h6 className="card-subtitle text-white" style={{ fontSize: '0.875rem' }}>{formatTimestamp(item?.created_at.split("T"))}</h6>
                                        </div>
                                        <AudioPlayer
                                            src={"https://ztalk.in:5112/" + item.file}
                                            onPlay={() => console.log("Playing...")}
                                            style={{ width: '100%', marginTop: '10px', borderRadius: "10px" }}
                                            customVolumeControls={[]}
                                            customAdditionalControls={[
                                                <a
                                                    key={`download-${item._id}`}
                                                    href={"https://ztalk.in:5112/" + item.file}
                                                    download
                                                    className="btn btn-sm btn-primary"
                                                    style={{ padding: '0.25rem 0.5rem' }}
                                                    target="_blank"
                                                >
                                                    <i className="fas fa-download"></i>
                                                </a>
                                            ]}
                                        />
                                        {
                                            path == "audio_room"
                                                ?
                                                <button onClick={() => {
                                                    set_id(item._id)
                                                    setShowPopupAll(false)
                                                    setShowPopup(true)
                                                }} className="btn btn-sm btn-danger position-absolute" style={{ top: '-1rem', right: '-0.6rem' }}>
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                                :
                                                null

                                        }

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {showPopup && (
                <div className="position-fixed top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1050 }}>
                    <div className="bg-white p-4 rounded shadow-lg">
                        <h3 className="mb-4">Confirm Delete {showPopupAll ? "All" : null}</h3>
                        <p className="mb-4">Are you sure you want to delete {showPopupAll ? "all" : null} recordings?</p>
                        <div className="d-flex justify-content-end">
                            <button
                                className="btn btn-danger me-2"
                                onClick={() => {
                                    // Handle delete all logic here
                                    handlepopupconfirm()


                                }}
                            >
                                Confirm Delete
                            </button>
                            <button
                                className="btn btn-secondary"
                                onClick={() => {

                                    setShowPopupAll(false)
                                    setShowPopup(false)

                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}


            <div className="pagination" style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                <button className='btn btn-primary'
                    onClick={() => page > 1 ? setpage(pV => pV - 1) : console.log("")}
                //   onClick={handlePrevious} disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span style={{ marginLeft: 10, marginRight: 10 }}>
                    {page}/{totalPages}
                    {/* {currentPage} of {totalPages} */}
                </span>
                <button className='btn btn-primary'
                    onClick={() => page < totalPages ? setpage(pV => pV + 1) : console.log("")}
                //   onClick={handleNext} disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>

        </>
    );
}

export default AudioList