import React, { useState } from 'react'
import SideMenu from './layouts/SideMenu'
import TopBar from './layouts/TopBar'
import MobileMenu from './layouts/MobileMenu'
import { DataGrid } from '@mui/x-data-grid';
import GeneralReport from './layouts/GeneralReport';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Modal } from '@mui/material';
import './cssClasses/custom.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getBearerToken, postBearerToken, putBearerToken } from '../../utils/Apis';
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

const A_add_room = () => {

    const location = useLocation()
    console.log(location)
    React.useEffect(() => {
        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();
        return () => {
            // document.body.removeChild(externalScript);
        };
    }, []);


    const [name, set_name] = React.useState("")
    const [password, set_password] = React.useState("")
    const [is_active, setis_active] = useState("")
    const [host_of_room, sethost_of_room] = useState("")


    const [message, setmessage] = useState("")
    const [messageView, setmessageView] = useState(false)
    const [loader, setloader] = useState(false)
    const [fetchLoader, setfetchLoader] = useState(false)

    const [user_data, setuser_data] = useState([])

    React.useEffect(() => {
        fetch_users()
        if(location?.state?.id){
            fetch_details()
        }
    }, [])


    const fetch_details = async () => {
        getBearerToken("room?_id="+location.state.id).then((result) => {
            if (result.error == 0) {
                console.log(result.data)
                set_name(result.data.name)
                set_password(result.data.password)
                sethost_of_room(result.data.host_of_room)
                setis_active(result.data.is_active == true ? "Active" : "Inactive")

            } else {
                setfetchLoader(false)

                // notify(JSON.stringify(result.messages))
            }
        })
    }

    const fetch_users = async () => {
        setfetchLoader(true)
        getBearerToken("users?for_admin=1&type=SubAdmin").then((result) => {
            if (result.error == 0) {
                console.log(result.data)
                setuser_data(result.data)
                setfetchLoader(false)

            } else {
                setfetchLoader(false)

                // notify(JSON.stringify(result.messages))
            }
        })
    }

    const submit = async () => {
        setloader(true)


        let url = "room"

        

        let json;

        if (location.pathname == "/create_room") {
            json = {
                name: name,
                host_of_room: host_of_room,
                password: password,
                is_active: is_active == "Active" ? true : false,
            }
        }
        if (location.pathname == "/edit_room") {
            if (password != "") {
                json = {

                    name: name,
                    host_of_room: host_of_room,
                    password: password,
                    is_active: is_active == "Active" ? true : false,
                }
            } else {
                json = {

                    name: name,
                    host_of_room: host_of_room,
                    is_active: is_active == "Active" ? true : false,

                }

            }
            json['_id'] = location.state.id
        }
        console.log(json)

        if (location.pathname == "/create_room") {

            if (name == "" || password == "" | host_of_room == "") {
                setmessage("All fields are Compulsary")
                setmessageView(true)
                setloader(false)
            } else {

                postBearerToken(url, json).then((result) => {
                    if (result.error == 0) {
                        set_name("")
                        sethost_of_room("")
                        set_password("")
                        setmessage("Success")
                        setmessageView(true)
                        setloader(false)
                    } else {
                        setmessage(result.message)
                        setmessageView(true)
                        setloader(false)

                    }

                })

            }
        }
        if (location.pathname == "/edit_room") {
            if (name == "") {
                setmessage("Name are Compulsary")
                setmessageView(true)
                setloader(false)
            } else {

                putBearerToken(url, json).then((result) => {
                    if (result.error == 0) {
                        setmessage("Success")
                        setmessageView(true)
                        setloader(false)
                    } else {
                        setmessage(result.message)
                        setmessageView(true)
                        setloader(false)

                    }

                })

            }
        }



    }



    return (
        <div className="py-5 md:py-0">
            {/* <!-- BEGIN: Mobile Menu --> */}
            <div className="mobile-menu md:hidden">
                <MobileMenu />

            </div>
            {/* <!-- END: Mobile Menu -->
        <!-- BEGIN: Top Bar --> */}
            <TopBar />
            {/* <!-- END: Top Bar --> */}
            <div className="flex overflow-hidden">
                {/* <!-- BEGIN: Side Menu --> */}
                <SideMenu />
                {/* <!-- END: Side Menu -->
            <!-- BEGIN: Content --> */}
                <div className="content">
                    <div className="grid grid-cols-12 gap-12">
                        <div className="col-span-12 2xl:col-span-12">
                            <div class="grid grid-cols-12 gap-12 mt-5 w-100">
                                <div class="intro-y col-span-12 lg:col-span-12">

                                    {messageView ?
                                        <div className='w-100' style={{ background: "lightblue", borderWidth: 1, borderRadius: 3, padding: 10, display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                                            <label>{message}</label>
                                            <div onClick={() => setmessageView(false)} style={{ cursor: "pointer" }} className="side-menu__icon"> <i data-lucide="x"></i> </div>
                                        </div>
                                        : null}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-12">



                            {fetchLoader == false ?

                                <div class="grid grid-cols-12 gap-6 mt-5">
                                    <div class="intro-y col-span-12 lg:col-span-12">
                                        <div class="intro-y box p-5">
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Host</label>
                                                <select className='form-control'
                                                    value={host_of_room}
                                                    onChange={(e) => sethost_of_room(e.target.value)}
                                                >
                                                    <option value=""> Select Host </option>

                                                    {
                                                        user_data && user_data.length > 0
                                                            ?
                                                            user_data.map((item, index) => (
                                                                <option value={item._id}> {item.slug_name} </option>
                                                            ))
                                                            :
                                                            null

                                                    }
                                                </select>
                                            </div>

                                            <div className='mt-2' >
                                                <label style={{ display: "flex", justifyContent: "start" }}>Full Name</label>
                                                <input id="crud-form-1"
                                                    value={name}
                                                    onChange={(e) => set_name(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>

                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Password</label>
                                                <input id="crud-form-1"
                                                    value={password}
                                                    onChange={(e) => set_password(e.target.value)}
                                                    type="password" class="form-control w-full" />
                                            </div>



                                            <div className="mt-4" style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                                                <input type="radio" checked={is_active == "Active" ? true : false} name="is_active" className='mr-2' onClick={() => setis_active("Active")} /> Active
                                                <input type="radio" checked={is_active == "Inactive" ? true : false} name="is_active" className='mr-2 ml-4' onClick={() => setis_active("Inactive")} /> Inactive
                                            </div>
                                            {
                                                loader
                                                    ?
                                                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                                                        <i data-loading-icon="circles" class="w-8 h-8"></i>
                                                        <div class="text-center text-xs mt-2">Loading</div>
                                                    </div>
                                                    :
                                                    <div class="text-right mt-5">
                                                        {/* <button type="button" class="btn btn-outline-secondary w-24 mr-1">Cancel</button> */}
                                                        <button onClick={() => submit()} type="button" class="btn btn-primary w-24">Save</button>
                                                    </div>
                                            }


                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>

                    </div>
                </div>
                {/* <!-- END: Content --> */}
            </div>


        </div>
    )
}

export default A_add_room