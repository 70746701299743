import React, { useState } from 'react'
import SideMenu from './layouts/SideMenu'
import TopBar from './layouts/TopBar'
import MobileMenu from './layouts/MobileMenu'
import { DataGrid } from '@mui/x-data-grid';
import GeneralReport from './layouts/GeneralReport';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import { Modal } from '@mui/material';
import './cssClasses/custom.css'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { BASE_URL, getBearerToken, postBearerToken, putBearerToken } from '../../utils/Apis';
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import axios from 'axios';
import { Constants } from '../../utils/Constants';

const A_profile = () => {

    const location = useLocation()
    console.log(location)
    React.useEffect(() => {
        const LoadExternalScript = () => {
            const externalScript = document.createElement("script");
            // externalScript.onerror = loadError;
            externalScript.id = "external";
            externalScript.async = true;
            externalScript.type = "text/javascript";
            externalScript.setAttribute("crossorigin", "anonymous");
            document.body.appendChild(externalScript);
            externalScript.src = '../dist/js/app.js';
        };
        LoadExternalScript();
        return () => {
            // document.body.removeChild(externalScript);
        };
    }, []);


    const [firstName, setfirstName] = React.useState("")
    const [phone, set_phone] = React.useState("")
    const [from_date, set_from_date] = React.useState("")
    const [to_date, set_to_date] = React.useState("")
    const [slug_name, set_slug_name] = React.useState("")
    const [password, set_password] = React.useState("")
    const [confirm_password, set_confirm_password] = React.useState("")
    const [show_password, set_show_password] = React.useState("")
    const [is_active, setis_active] = useState("")
    const [room, set_room] = React.useState("")
    const [client_id, set_client_id] = React.useState("")
    const [client_secret, set_client_secret] = React.useState("")
    const [oauth_id, set_oauth_id] = React.useState("")

    const [message, setmessage] = useState("")
    const [messageView, setmessageView] = useState(false)
    const [loader, setloader] = useState(false)
    const [fetchLoader, setfetchLoader] = useState(false)

    const [file, setfile] = useState("")

    const [user_data, setuser_data] = useState(null)
    const [rendered, setrendered] = useState(0)
    React.useEffect(() => {
        fetch_users()
    }, [rendered])

    const fetch_users = async () => {
        setfetchLoader(true)
        getBearerToken("users").then((result) => {
            if (result.error == 0) {
                console.log(result.data)
                setuser_data(result.data)


                setfirstName(result.data.first_name)
                set_phone(result.data.phone)
                set_from_date(result.data.from_date.split("T")[0])
                set_to_date(result.data.to_date.split("T")[0])
                set_slug_name(result.data.slug_name)
                set_room(result.data?.room?._id)
                set_client_id(result.data.client_id)
                set_client_secret(result.data.client_secret)
                set_oauth_id(result.data.oauth_id)
                setfile(result.data.image)
                setis_active(result.data.is_active == true ? "Active" : "Inactive")
                setfetchLoader(false)


            } else {
                setfetchLoader(false)

                // notify(JSON.stringify(result.messages))
            }
        })
    }

    const submit = async () => {
        setloader(true)


        let url = ""

        if (location.pathname == "/profile") {
            url = "users"
        }
        let json;


        if (location.pathname == "/profile") {
            if (password != "") {
                json = {

                    first_name: firstName,
                    phone: phone,
                    password: password,
                    show_password: password,
                }
            } else {
                json = {

                    first_name: firstName,
                    phone: phone,
                }
            }
        }
        console.log(json)

        if (location.pathname == "/profile") {
            if (phone == "") {
                setmessage("Phone is Compulsary")
                setmessageView(true)
                setloader(false)
            } else {

                if (confirm_password == password) {
                    putBearerToken(url, json).then((result) => {
                        if (result.error == 0) {
                            setmessage("Success")
                            setmessageView(true)
                            setloader(false)
                        } else {
                            setmessage(result.message)
                            setmessageView(true)
                            setloader(false)

                        }

                    })
                } else {
                    setmessage("Password Mismatch")
                    setmessageView(true)
                    setloader(false)
                }
            }
        }



    }


    let upload_update_docs = async (e) => {
        let formData = new FormData();
        let token = localStorage.getItem(Constants.APP_KEY)

        formData.append("file", e.target.files[0]);
    
        try {
          const response = await axios({
            method: "PUT",
            url: BASE_URL+"users_image_update",
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + token,
            },
            data: formData,
          });
          console.log("upload docs", response);
        //   setrenderedData((ov) => ov + 1);
        setrendered(o => o+1)
        } catch (error) {
          console.log(error);
        }
      };


    return (
        <div className="py-5 md:py-0">
            {/* <!-- BEGIN: Mobile Menu --> */}
            <div className="mobile-menu md:hidden">
                <MobileMenu />

            </div>
            {/* <!-- END: Mobile Menu -->
        <!-- BEGIN: Top Bar --> */}
            <TopBar />
            {/* <!-- END: Top Bar --> */}
            <div className="flex overflow-hidden">
                {/* <!-- BEGIN: Side Menu --> */}
                <SideMenu />
                {/* <!-- END: Side Menu -->
            <!-- BEGIN: Content --> */}
                <div className="content">
                    <div className="grid grid-cols-12 gap-12">
                        <div className="col-span-12 2xl:col-span-12">
                            <div class="grid grid-cols-12 gap-12 mt-5 w-100">
                                <div class="intro-y col-span-12 lg:col-span-12">

                                    {messageView ?
                                        <div className='w-100' style={{ background: "lightblue", borderWidth: 1, borderRadius: 3, padding: 10, display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                                            <label>{message}</label>
                                            <div onClick={() => setmessageView(false)} style={{ cursor: "pointer" }} className="side-menu__icon"> <i data-lucide="x"></i> </div>
                                        </div>
                                        : null}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12 2xl:col-span-12">

                            <div>
                                <img src={BASE_URL+file} style={{width:"80px" , height:"80px"}}></img> 
                            </div>

                            {fetchLoader == false ?

                                <div class="grid grid-cols-12 gap-6 mt-5">
                                    <div class="intro-y col-span-12 lg:col-span-12">
                                        <div class="intro-y box p-5">
                                            {location.pathname == "/create_admin" || location.pathname == "/edit_admin" || location.pathname == "/profile" ? null :
                                                <div className='mt-2'>
                                                    <label style={{ display: "flex", justifyContent: "start" }}>User Type</label>
                                                    <select className='form-control'
                                                        value={room}
                                                        onChange={(e) => set_room(e.target.value)}
                                                    >
                                                        <option value=""> Select Meeting </option>
                                                    </select>
                                                </div>
                                            }

                                            <div className='mt-2' >
                                                <label style={{ display: "flex", justifyContent: "start" }}>Full Name</label>
                                                <input id="crud-form-1"
                                                    value={firstName}
                                                    onChange={(e) => setfirstName(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2' >
                                                <label style={{ display: "flex", justifyContent: "start" }}>Mobile Number</label>

                                                <input id="crud-form-1"
                                                    value={phone}
                                                    onChange={(e) => set_phone(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>

                                            <div className='mt-2' >
                                                <label style={{ display: "flex", justifyContent: "start" }}>From Date</label>

                                                <input id="crud-form-1"
                                                    value={from_date}
                                                    // onChange={(e) => set_phone(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2' >
                                                <label style={{ display: "flex", justifyContent: "start" }}>To Date</label>

                                                <input id="crud-form-1"
                                                    value={to_date}
                                                    // onChange={(e) => set_phone(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>

                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>User Name</label>
                                                <input id="crud-form-1"
                                                    value={slug_name}
                                                    onChange={(e) => set_slug_name(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Password</label>
                                                <input id="crud-form-1"
                                                    value={password}
                                                    onChange={(e) => set_password(e.target.value)}
                                                    type="password" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Confirm Password</label>
                                                <input id="crud-form-1"
                                                    value={confirm_password}
                                                    onChange={(e) => set_confirm_password(e.target.value)}
                                                    type="text" class="form-control w-full" />
                                            </div>
                                            <div className='mt-2'>
                                                <label style={{ display: "flex", justifyContent: "start" }}>Image</label>
                                                <input id="crud-form-1"
                                                    onChange={(e) => upload_update_docs(e)}
                                                    type="file" class="form-control w-full" />
                                            </div>
                                            {location.pathname == "/create_admin" || location.pathname == "/edit_admin" || location.pathname == "/profile" ? null :
                                                <>
                                                    <div className='mt-2'>
                                                        <label style={{ display: "flex", justifyContent: "start" }}>O Auth Account Id</label>
                                                        <input id="crud-form-1"
                                                            value={oauth_id}
                                                            onChange={(e) => set_oauth_id(e.target.value)}
                                                            type="text" class="form-control w-full" />
                                                    </div>
                                                    <div className='mt-2'>
                                                        <label style={{ display: "flex", justifyContent: "start" }}>Client Id</label>
                                                        <input id="crud-form-1"
                                                            value={client_id}
                                                            onChange={(e) => set_client_id(e.target.value)}
                                                            type="text" class="form-control w-full" />
                                                    </div>
                                                    <div className='mt-2'>
                                                        <label style={{ display: "flex", justifyContent: "start" }}>Client Secret</label>
                                                        <input id="crud-form-1"
                                                            value={client_secret}
                                                            onChange={(e) => set_client_secret(e.target.value)}
                                                            type="text" class="form-control w-full" />
                                                    </div>
                                                    <div className='mt-4' style={{ display: "flex", justifyContent: "start" }}>
                                                        <input id="radio-switch-1" className="form-check-input" type="radio" name="vertical_radio_button" value="vertical-radio-chris-evans"></input>
                                                        <label className="form-check-label mr-4" for="radio-switch-1" >Active</label>
                                                        <input id="radio-switch-1" className="form-check-input" type="radio" name="vertical_radio_button" value="vertical-radio-chris-evans"></input>
                                                        <label className="form-check-label" for="radio-switch-1">Inactive</label>
                                                    </div>
                                                </>
                                            }
                                            {/* <div className="mt-4" style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                                                <input type="radio" checked={is_active == "Active" ? true : false} name="is_active" className='mr-2' onClick={() => setis_active("Active")} /> Active
                                                <input type="radio" checked={is_active == "Inactive" ? true : false} name="is_active" className='mr-2 ml-4' onClick={() => setis_active("Inactive")} /> Inactive
                                            </div> */}
                                            {
                                                loader
                                                    ?
                                                    <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
                                                        <i data-loading-icon="circles" class="w-8 h-8"></i>
                                                        <div class="text-center text-xs mt-2">Loading</div>
                                                    </div>
                                                    :
                                                    <div class="text-right mt-5">
                                                        {/* <button type="button" class="btn btn-outline-secondary w-24 mr-1">Cancel</button> */}
                                                        <button onClick={() => submit()} type="button" class="btn btn-primary w-24">Save</button>
                                                    </div>
                                            }


                                        </div>
                                    </div>
                                </div>
                                :
                                null
                            }
                        </div>

                    </div>
                </div>
                {/* <!-- END: Content --> */}
            </div>


        </div>
    )
}

export default A_profile