import React, { useState } from 'react'
import { getBearerToken } from '../utils/Apis'
import { useLocation, useParams } from 'react-router-dom'
import { Parser } from "html-to-react";

const OAuth = () => {
  const htmlParser = new Parser();
  React.useEffect(() => {
    fetch_users()
  }, [])

  const {_id} = useParams()
  const location = useLocation()
  const location_code = location.search.split("=")[1]

  const [data, setdata] = useState("")
  const fetch_users = async () => {


    getBearerToken("oauth_authorize/"+location_code).then((result) => {
      if (result.error == 0) {
        setdata(result.data)
      } else {
        // notify(JSON.stringify(result.messages))
      }
    })

  }

  return (
    <div>{htmlParser.parse(data)}</div>
  )
}

export default OAuth