import React from 'react';
import logo from "../logo.svg"


const PrivacyPolicy = () => {
    return (
        <>
            <div className="bg-dark flex justify-center items-center shadow py-3" style={{ background: "black" }}>
                <a href="" class="-intro-x flex items-center "><span class="text-white text-lg ml-3"> ZTALK </span></a>
            </div>
            <section className="privacy-policy-section mt-5">
                <div className="">

                    <div className="row justify-center">
                        <div className="privacy_policybox">
                            <div className="text-center my-3">
                                <h3>Privacy Policy</h3>
                            </div>
                            <div className="privacy-policy-content" style={{textAlign:"start"}}>
                                <p className='mt-5'><strong>1. Introduction</strong></p>
                                <p>Welcome to ZTalk ("we", "our", "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and protect your information when you use our mobile application ("App") which provides meeting services.</p>

                                <p><strong>2. Information We Collect</strong></p>
                                <p>We collect several types of information to provide and improve our services to you, including:</p>
                                <ul>
                                    <li><strong>Personal Information:</strong> When you register on our App, we collect personal details such as your name, location, phone number, email address, and gender.</li>
                                    <li><strong>Device Information:</strong> We collect information about the mobile device you use to access our App, including the device's unique identifier (device ID) and Bluetooth access.</li>
                                    <li><strong>Address:</strong> We collect your shipping address to deliver products to you.</li>
                                </ul>

                                <p><strong>3. How We Use Your Information</strong></p>
                                <p>We use the information we collect for various purposes, including:</p>
                                <ul>
                                    <li><strong>To Provide Services:</strong> To process your orders, deliver the products, and provide customer support.</li>
                                    <li><strong>To Improve Our Services:</strong> To understand how our App is used, improve our services, and develop new features.</li>
                                    <li><strong>To Communicate with You:</strong> To send you updates, promotional materials, and other information that may be of interest to you. You can opt out of receiving promotional communications at any time.</li>
                                    <li><strong>To Ensure Security:</strong> To protect our App and users from security breaches and fraud.</li>
                                </ul>

                                <p><strong>4. Sharing Your Information</strong></p>
                                <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:</p>
                                <ul>
                                    <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our App, conducting our business, or providing services to you, as long as these parties agree to keep this information confidential.</li>
                                    <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
                                    <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.</li>
                                </ul>

                                <p><strong>5. Data Security</strong></p>
                                <p>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information. These measures include encryption, secure socket layer (SSL) technology, and regular monitoring of our systems for potential vulnerabilities and attacks.</p>

                                <p><strong>6. Your Choices</strong></p>
                                <p>You have several choices regarding your information:</p>
                                <ul>
                                    <li><strong>Access and Update:</strong> You can access and update your personal information by logging into your account and making the necessary changes.</li>
                                    <li><strong>Opt-Out:</strong> You can opt out of receiving promotional emails from us by following the unsubscribe link provided in those emails. However, you will still receive transactional emails related to your orders.</li>
                                    <li><strong>Delete Account:</strong> You can request to delete your account and personal information by contacting our customer support.</li>
                                </ul>

                                <p><strong>7. Children's Privacy</strong></p>
                                <p>Our App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently received personal information from a user under the age of 13, we will delete such information from our records.</p>

                                <p><strong>8. Changes to This Privacy Policy</strong></p>
                                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Effective Date" at the top. You are advised to review this Privacy Policy periodically for any changes.</p>

                                <p><strong>9. Contact Us</strong></p>
                                <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                                <p>ZTalk<br />
                                    <a href="mailto:nancys1040@gmail.com"><strong>nancys1040@gmail.com</strong></a></p>

                                <p>Thank you for choosing ZTalk. We are committed to protecting your privacy and ensuring a secure and enjoyable experience with our meeting services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PrivacyPolicy;